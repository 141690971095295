
import { Item } from '@/common/models/Item.model';
import { clientModule } from '@/modules/client/client.vuex-module';
import { orderModule, ModelError } from '@/modules/order/orderModule.vuex-module';
import { Options, Vue } from 'vue-class-component';
@Options({
	name: 'TimeCancelView',
	components: {},
})
export default class TimeCancelView extends Vue {
	error: string | null = null;
	loading = false;
	get package(): Item | null {
		return orderModule.openItem;
	}
	onAction(): void {
		this.loading = true;
		orderModule
			.cancelActivity(this.$route.params.orderEntityTimeKey as string)
			.then((res) => {
				if (!res) {
					this.error = `Unable to cancel activity. Please call us at ${clientModule.location?.PublicPhoneNumber}`;
				} else if (res.Order) {
					if (res.Order.Balance == 0) {
						this.$router.push({ name: 'OrderLanding', params: { success: 'true' } });
					} else {
						orderModule.isEditing = true;
						if (this.$route.params.orderEntityTimeKey) orderModule.selectByOrderEntityTimeKey(this.$route.params.orderEntityTimeKey as string);
						this.$router.push({ name: 'OrderTimeRefund', params: { cancellation: 'true' } });
					}
					return;
				} else if (res.ModelErrors) {
					console.log('Model Errors!');
					this.error = res.ModelErrors.map((e: ModelError) => e.Value)
						.flat()
						.join(', ');
				}
			})
			.finally(() => {
				this.loading = false;
			})
			.catch(() => {
				this.error = `Unable to cancel activity. Please call us at ${clientModule.location?.PublicPhoneNumber}`;
			});
	}
}
